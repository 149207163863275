import React from 'react';

import Layout from '../components/Layout';
import { Typography } from '../components/Typography';
import { Wrapper } from '../components/Wrapper';
import { Section } from '../components/Section';

const index = () => (

  <Layout>
    <Wrapper>
      <Section py="var(--spacing-5)">
        <Typography as="h1">Styles</Typography>
        <Typography as="h1">This is an H1</Typography>
        <Typography as="h2">This is an H2</Typography>
        <Typography as="h3">This is an H3</Typography>
        <Typography as="h4">This is an H4</Typography>
        <Typography as="h5">This is an H5</Typography>
        <Typography as="h6">This is an H6</Typography>
        <Typography>
          This is a paragraph. Lorem ipsum dolor sit amet, consectetur
          adipisicing elit. Quidem distinctio praesentium perferendis
          optio suscipit corrupti adipisci magni id aliquid incidunt! Hic,
          officia. Placeat, nulla rerum! Molestiae, enim id error nemo,
          explicabo dolor commodi et repellat amet magni nulla ad quia
          ipsam, dolores corporis nihil quos obcaecati? Porro repellendus
          natus voluptatem.
        </Typography>
        <Typography as="h4">Unordered Lists</Typography>
        <Typography as="ul">
          <li>Donec non tortor in arcu mollis feugiat</li>
          <li>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit
          </li>
          <li>Donec id eros eget quam aliquam gravida</li>
          <li>Vivamus convallis urna id felis</li>
          <li>Nulla porta tempus sapien</li>
        </Typography>

        <Typography as="h4">Ordered Lists</Typography>
        <Typography as="ol">
          <li>Donec non tortor in arcu mollis feugiat</li>
          <li>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit
          </li>
          <li>Donec id eros eget quam aliquam gravida</li>
          <li>Vivamus convallis urna id felis</li>
          <li>Nulla porta tempus sapien</li>
        </Typography>

        <Typography as="h4">Code Blocks</Typography>
      </Section>
    </Wrapper>
  </Layout>

);

export default index;
